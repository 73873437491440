<template>
	<back-home/>
	<div class="page">
		<div class="s1 p-card m-3">
			<div class="flex ml-2 pt-2 fs-16 pointer">
				<div class="flex-1">注意事项：</div>
			</div>
			<div class="ml-3 fs-12 color-gray mt-1">1.因微信风控推送消息数有上限，超出则不可发送；</div>
			<div class="ml-3 fs-12 color-gray mt-1">2.视频、表情包暂不可转发；</div>
			<div class="ml-3 fs-12 color-gray mt-1">3.链接加价以被采集的素材群链接为准，当其他店主转发则加价无法改变！！</div>
			<div class="ml-3 fs-12 color-gray pb-2 mt-1">4.基于微信风控和更好的用户体验，建议被采集对象控制素材信息数，分时段宣发；</div>
		</div>
		<TimePicker @confirm="timePickerConfirm"
		            :startTime="timeRange[0]" :endTime="timeRange[1]"/>
		<div class="card s1 p-card m-3 p-3">
			<div class="flex">
				<div class="fs-16">采集对象</div>

				<u class="color-danger flex-right fs-12"
				   v-bury="'caijiduixiangtianjia'"
				   @click="toGroup()"
				   v-if="gatherInfo.memberWxId =='暂未设置'">立即设置</u>
				<u class="color-danger flex-right fs-12"
				   v-bury="'bohuoqunxiugai'"
				   @click="toGroup()"
				   v-else>更改</u>
			</div>
			<div class="select-object mt-2">
				<div class="select-object__group" :class="{'no-pb' : gatherInfo.routeType === 0}">
					<template v-if="gatherInfo.chatroomId">
						<div class="group-sign flex">
							<!--							<img class="group-img" :src="gatherInfo.groupHead">-->
							<AvatarImg :avatar="gatherInfo.groupHeadImg" avatarHeight=".48rem" :round="false"/>
							<div class="group-name ml-2 fs-14 b text-flow">
								{{ gatherInfo.groupName ? gatherInfo.groupName : '未命名' }}
							</div>
						</div>
						<!--        <div class="group-personnel mt-4 flex  wrap h-between">-->
						<div class="group-personnel mt-4  h-between" v-if="gatherInfo.routeType === 0">
							<template v-for="i in gatherInfo.teacherInfos">
								<div class="flex column group-personnel__item">
									<img class="robotAvatar group-personnel__item--img"
									     :src="i.headImgUrl ? i.headImgUrl : defaultAvatar">
									<p class="b mt-1 fs-12 text-ellipsis text-center group-personnel__item--name">{{ i.nickName }}</p>
								</div>
							</template>
						</div>
					</template>

					<div class="no-object" v-else>
						<div class="flex column mt-3">
							<div class="relative">
								<AvatarImg avatarHeight=".48rem"/>
							</div>
							<div class="fs-12 color-gray mt-1">暂未设置</div>
						</div>
					</div>
				</div>


			</div>


			<!--			<div class="flex column mt-2">
							<div class="relative">
								<img class="gatherInfoAvatar"
										 mode="widthFix"
										 :src="gatherInfo.headImgUrl || require('../../assets/avatar.png')">
							</div>

							<div class="b" v-if="gatherInfo.memberWxId">{{ gatherInfo.nickName }}</div>
							<div class="color-gray" v-else>暂未设置</div>
						</div>
						<div class="fs-10 color-gray mt-1 text-center" v-if="gatherInfo.groupName">
							来自群聊「{{ gatherInfo.groupName }}」
						</div>-->
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import chatList from '@/components/chatList/index.vue'
import { defineAsyncComponent } from 'vue'
import { ApiGetFollowTimeRange, ApiGetTeacher, ApiSetFollowTimeRange } from '@/api/FollowLive'

const defaultAvatar = require('../../assets/avatar.png')

export default {
	name: 'Index',
	components: {
		TimePicker: defineAsyncComponent(() => import('@/components/TimePicker')),
		AvatarImg: defineAsyncComponent(() => import('@/components/AvatarImg/index'))
	},
	data() {
		return {
			// 是否指定人选
			isAppoint: false,
			collectionObject: [],
			defaultAvatar: defaultAvatar,

			timeRange: ['08:30', '12:00'], // 开始/结束时间
			timePickerShow: false,
			columns: [],
			list: [],
			loading: false,
			finished: false,
			pageIndex: 1,
			pageSize: 15,

			gatherInfo: {
				headImgUrl: '',
				nickName: '暂未设置',
				groupName: '暂未设置'
			},
			id: 0
		}
	},
	computed: {
		...mapGetters(['globalData'])
	},
	created() {
		this.getTimeRange()
		this.getGatherInfo()
	},
	methods: {
		async getTimeRange() {
			try {
				const res = await ApiGetFollowTimeRange()
				this.timeRange = [res.startTimeRange, res.endTimeRange]
			} catch (e) {
				console.log('ApiGetFollowTimeRange', e)
			}
		},

		async getGatherInfo() {
			try {
				const res = await ApiGetTeacher()
				this.gatherInfo = res || {}
			} catch (e) {
				console.log('getGatherInfo', e)
			}
		},
		toGroup() {
			this.$router.push('/selectGroupGather')
		},
		async timePickerConfirm(startTime, endTime) {
			this.timeRange[0] = startTime
			this.timeRange[1] = endTime
			this.$Toast.loading({
				message: '提交中...',
				forbidClick: true
			})
			try {
				await ApiSetFollowTimeRange({
					startTimeRange: startTime,
					endTimeRange: endTime
				})
				this.timePickerShow = false
				this.$Toast('修改成功')
				this.$Toast.clear()
			} catch (e) {
				this.$Toast.clear()
			}
		}
	}
}
</script>

<style scoped lang="scss">
.select-object {
	.select-object__group {
		padding: px(20) 0;
		// border-radius: px(4);
		box-shadow: 0 px(2) px(6) 0 rgba(0, 0, 0, 0.06);
		background: #FFFFFF;
		border-radius: px(12);

		.group-sign {
			padding: 0 px(15);

			.group-img {
				width: px(48);
				height: px(48);
				border-radius: px(4);
			}

			.group-name {
				color: #181818;
				line-height: px(20);
			}
		}

		.group-personnel {
			background: #F8FAFA;
			border-radius: px(4);
			padding: px(18) px(20) 0;
			display: grid;
			justify-content: space-between;
			grid-gap: px(8);
			grid-template-columns: repeat(auto-fill, px(60));

			.group-personnel__item {
				margin-bottom: px(20);

				.group-personnel__item--img {
					width: px(40);
					height: px(40);
				}

				.group-personnel__item--name {
					width: px(60);
				}
			}
		}
	}

	.no-pb {
		padding-bottom: 0;
	}
}

@import "index";
</style>
